<template>
    <v-row no-gutters>
        <v-col cols="12" sm="12" md="4">
            <label class="f-label">{{$t(label)}}</label>
        </v-col>
        <v-col cols="12" sm="12" md="8">
            <v-menu 
                v-model="dateMenuVisible"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :value="content | formattedDate"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on" 
                        hide-details
                        required
                        outlined
                        dense
                        class="datepick-input"
                        :rules="rules" 
                    >
                    </v-text-field>
                </template>

                <v-date-picker 
                    v-model="content"
                    @input="handle"
                    color="teal"
                    :first-day-of-week="1"
                >
                </v-date-picker>
            </v-menu>

        </v-col>
    </v-row>
</template>
<script>
import sys from '@/services/system';

export default {
    name: "FieldEditDate",
    props: {
        label: {
            type: String,
            default: null
        },
        value: {
            type: String,
            default: null
        },
        rules: null
    },
    data() {
        return {
            dateMenuVisible: false,
            content: this.value
        }
    },
    methods: {
        handle(e) {
            this.dateMenuVisible = false
            this.$emit('input', e);
        }
    },
    filters: {
        formattedDate: function (value) {
            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        }
    },
};
</script>

